import React from 'react';
import './download.css';

function TelechargementPage() {
  const handleTestFlightClick = () => {
    window.open("https://apps.apple.com/us/app/testflight/id899247664", "_blank", "noopener,noreferrer");
  };

  return (
    <div className="telechargement-page">
      <section className="app-section">
        <h3> Clarity pour smartphone</h3>
        <p><strong>Obtenez l'application Clarity sur votre smartphone, disponible sur Android et iOS :</strong></p>
        <ul>
          <li>Dictée transcrite en direct 🎙️</li>
          <li>Scanner des documents 📄 à analyser par l'IA</li>
          <li>Gestion facile des rapports au quotidien avec la fonction de brouillon 📝</li>
          <li>Et encore plus de fonctionnalités à découvrir !</li>
        </ul>

        <section className="download-links">
        <ul>
          <li>
            <p><strong>Pour Android :</strong> Téléchargez directement le fichier APK et installez-le.</p>
            <a href="https://clarity.gbm-medical.ch/telechargements/Clarity.apk" download>
              Télécharger l'application Clarity sur Android 📱
            </a>
          </li>
          <li>
            <p><strong>Pour iPhone : </strong> 
              Installez d'abord &nbsp;
              <span className="testflight-link" role="link" onClick={handleTestFlightClick}>
                 TestFlight depuis l'App Store
              </span> 
              , puis utilisez le lien ci-dessous pour installer Clarity.
            </p>
            <a href="https://testflight.apple.com/join/4cWZWkm8" download>
              Télécharger l'application Clarity sur iPhone 📱
            </a>
          </li>
        </ul>
      </section>
      </section>

      <section className="feature-section">
        <h3>Clarity Desktop</h3>
        <h4>Profitez des fonctionnalités avancées avec Clarity Desktop :</h4>
        <ul>
        <li><strong>Utilisation sans installation sur les ordinateurs hospitaliers 🏥</strong></li>
<li>Insertion semi-automatique des rapports 🔄</li>
<li>Expérience rapide et fluide 🚀</li>
<li>Stockage local illimité pour vos rapports 📂</li>
        </ul>


      <section className="download-links">
        <ul>
          <li>
            <li><strong>Pour Windows :</strong> Version sans installation.</li>
            <a href="https://clarity.gbm-medical.ch/telechargements/Clarity.exe" download>
              Télécharger Clarity Desktop pour Windows 💻
            </a>
          </li>
          <li>
            <li><strong>Pour macOS :</strong> Version compatible.</li>
            <a href="https://clarity.gbm-medical.ch/telechargements/Clarity.dmg" download>
              Télécharger Clarity Desktop pour macOS 💻
            </a>
          </li>
        </ul>
      </section>
      </section>
    </div>
  );
}

export default TelechargementPage;