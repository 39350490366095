import { jsPDF } from 'jspdf';
import { Document, Packer, Paragraph, TextRun, AlignmentType } from 'docx';

export const exportToPDF = ({ userAddress, destinationAddress, concerneText, introductionText, locationDate, initialSalutation, finalSalutation, signature, reportContent }) => {
  const doc = new jsPDF();

  const addSection = (title, text, yPos) => {
    doc.setFont("helvetica", "bold");
    doc.text(title, 10, yPos);
    doc.setFont("helvetica", "normal");
    doc.text(text, 10, yPos + 10);
    return yPos + 20;
  };

  let yPosition = 20;
  if (userAddress) yPosition = addSection(userAddress, yPosition);
  if (destinationAddress) yPosition = addSection(destinationAddress, yPosition);
  if (concerneText) yPosition = addSection(concerneText, yPosition);
  if (locationDate) yPosition = addSection(locationDate, yPosition);
  if (initialSalutation) yPosition = addSection( initialSalutation, yPosition);
  if (introductionText) yPosition = addSection( introductionText, yPosition);

  // Ajouter le contenu du rapport principal
  if (reportContent) yPosition = addSection(reportContent, yPosition);

  if (finalSalutation) yPosition = addSection(finalSalutation, yPosition);
  if (signature) yPosition = addSection(signature, yPosition);

  doc.save('rapport.pdf');
};

export const exportToWord = async ({
  userAddress,
  destinationAddress,
  concerneText,
  introductionText,
  locationDate,
  initialSalutation,
  finalSalutation,
  signature,
  reportContent,
  includeUserAddress,
  includeDestinationAddress,
  includeConcerne,
  includeIntroduction,
  includeLocationDate,
  includeInitialSalutation,
  includeFinalSalutation,
  includeSignature
}) => {
  const doc = new Document({
    sections: [
      {
        children: [
          // Adresse de l'utilisateur (à gauche)
          ...(includeUserAddress && userAddress ? userAddress.split('\n').map(line => new Paragraph({
            children: [new TextRun({ text: line, font: "Arial", size: 24 })],
            alignment: AlignmentType.LEFT,
          })) : []),

          ...(includeUserAddress ? [new Paragraph("")] : []),

          // Adresse de destination (à droite)
          ...(includeDestinationAddress && destinationAddress ? destinationAddress.split('\n').map(line => new Paragraph({
            children: [new TextRun({ text: line, font: "Arial", size: 24 })],
            alignment: AlignmentType.RIGHT,
          })) : []),

          ...((includeUserAddress || includeDestinationAddress) ? [new Paragraph(""), new Paragraph(""), new Paragraph("")] : []),

          // Lieu et date
          ...(includeLocationDate && locationDate ? [new Paragraph({
            children: [new TextRun({ text: locationDate, font: "Arial", size: 24 })],
          }), new Paragraph(""), new Paragraph("")] : []),

          // Texte "Concerne:" en gras
          ...(includeConcerne && concerneText ? [new Paragraph({
            children: [new TextRun({ text: concerneText, bold: true, font: "Arial", size: 24 })],
          }), new Paragraph(""), new Paragraph("")] : []),

          // Salutation initiale
          ...(includeInitialSalutation && initialSalutation ? [new Paragraph({
            children: [new TextRun({ text: initialSalutation, font: "Arial", size: 24 })],
          }), new Paragraph("")] : []),

          // Texte d'introduction
          ...(includeIntroduction && introductionText ? [new Paragraph({
            children: [new TextRun({ text: introductionText, font: "Arial", size: 24 })],
          }), new Paragraph(""), new Paragraph("")] : []),

          // Contenu du rapport
          ...(reportContent
            ? reportContent.split("\n").map(line => new Paragraph({
                children: [new TextRun({ text: line, font: "Arial", size: 24 })],
                alignment: AlignmentType.JUSTIFIED,
              }))
            : []),

          ...(reportContent ? [new Paragraph(""), new Paragraph(""), new Paragraph("")] : []),

          // Salutation finale
          ...(includeFinalSalutation && finalSalutation ? [new Paragraph({
            children: [new TextRun({ text: finalSalutation, font: "Arial", size: 24 })],
          }), new Paragraph("")] : []),

          // Signature (alignée à droite)
          ...(includeSignature && signature ? [new Paragraph({
            children: [new TextRun({ text: signature, font: "Arial", size: 24 })],
            alignment: AlignmentType.RIGHT,
            spacing: { before: 400 },
          })] : []),
        ].filter(Boolean).flat(),
      },
    ],
  });

  const blob = await Packer.toBlob(doc);
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'rapport.docx';
  link.click();
};