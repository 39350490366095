// Button.js
import React from 'react';
import './Button.css';

function Button({ children, variant = 'primary', ...props }) {
  return (
    <button className={`button button-${variant}`} {...props}>
      {children}
    </button>
  );
}

export default Button;
