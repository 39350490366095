import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { format, isToday } from 'date-fns';
import './ReportList.css';
import { FaCopy, FaUndo, FaSync, FaBrain, FaFileMedical, FaPrint, FaArchive, FaEnvelope, FaChevronRight, FaFileWord } from 'react-icons/fa';
import Button from './Button';
import { exportToPDF, exportToWord } from './exportFunctions';
import ExportOptions from './ExportOptions';
import Notification from './Notification';
import 'react-quill/dist/quill.snow.css';
import CustomReactQuill from './CustomReactQuill';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const loadKeywordsFromLocalStorage = () => {
  const storedKeywords = localStorage.getItem('keywords');
  
  if (!storedKeywords) {
    const defaultKeywords = [
    'Actuellement',
    'Analyse',
    'Allergie',
    'Anamnèse',
    'Antécédent',
    'Attitude',
    'Comorbidité',
    'Compliance',
    'Conclusion',
    'Diagnostic',
    'Discussion',
    'Échographie',
    'Entrée',
    'Exposition',
    'Évaluation',
    'Evolution',
    'Examen',
    'Fonction',
    'Informations',
    'Indication',
    'Interprétation',
    'Imagerie',
    'Madame',
    'Médicament',
    'Médication',
    'Monsieur',
    'Mr',
    'Mme',
    'Objectif',
    'Observation', 
    'Plan',
    'Pronostic',
    'Proposition',
    'Prochain patient',
    'Prochain cas',
    'Radiologie',
    'Recommandation',
    'Réglages',
    'Remarque', 'Section', 'Prochaine section',
    'Statistique',
    'Status',
    'Suivi',
    'Synthèse',
    'Thérapie',
    'Traitement',

    //Scientifique
    'Abstract',
    'Introduction',
    'Objective',
    'Method',
    'Materials',
    'Study Design',
    'Statistical',
    'Results',
    'Limitations',

    //Allemand 
      'Aktuell',
      'Analyse',
      'Allergien',
      'Anamnese',
      'Vorgeschichte',
      'Einstellung',
      'Komorbiditäten',
      'Compliance',
      'Schlussfolgerung',
      'Diagnose',
      'Diskussion',
      'Ultraschall',
      'Eintritt',
      'Expositionen',
      'Bewertung',
      'Verlauf',
      'Untersuchung',     
      'Funktion',
      'Informationen',
      'Indikation',
      'Interpretation',
      'Bildgebung',
      'Frau',
      'Medikament',
      'Medikation',
      'Herr',
      'Hr',
      'Fr',
      'Ziel',
      'Beobachtung',
      'Prognose',
      'Vorschlag',
      'Empfehlung',
      'Einstellungen',
      'Bemerkung',
      'Abschnitt', 
      'Nächster Abschnitt',
      'Austritt',
      'Statistik',
      'Nachsorge',
      'Zusammenfassung',
      'Therapie',
      'Behandlung'
  ];

// Sauvegarde des mots-clés par défaut dans le localStorage
localStorage.setItem('keywords', defaultKeywords.join(', '));

return defaultKeywords;
}

// Si des mots-clés sont déjà présents, les charger
return storedKeywords.split(',').map(word => word.trim());
};

// Définir les modules de Quill avec les liaisons clavier personnalisées
const quillModules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    ['link'],
    ['clean'],
  ],
  keyboard: {
    bindings: {
      handleEnter: {
        key: 13,
        handler: function(range, context) {
          // Insérer un saut de ligne simple
          let cursorPosition = range.index;
          this.quill.insertText(cursorPosition, '\n');
          this.quill.setSelection(cursorPosition + 1);
        },
      },
    },
  },
  clipboard: {
    matchVisual: false, // Désactiver le comportement par défaut qui modifie le HTML collé
  },
};


// Fonctions utilitaires
const getLatestReportDate = (reports) => {
  if (reports.length === 0) return null;
  return reports.reduce((latestDate, report) => {
    const reportDate = new Date(report.date);
    return reportDate > latestDate ? reportDate : latestDate;
  }, new Date(reports[0].date));
};

const getOldestReportDate = (reports) => {
  if (reports.length === 0) return null;
  return reports.reduce((oldestDate, report) => {
    const reportDate = new Date(report.date);
    return reportDate < oldestDate ? reportDate : oldestDate;
  }, new Date(reports[0].date));
};

const mergeReports = (existingReports, newReports) => {
  const reportsMap = new Map();

  // Ajouter les rapports existants
  existingReports.forEach((report) => {
    reportsMap.set(report.messageID, report);
  });

  // Ajouter les nouveaux rapports
  newReports.forEach((report) => {
    if (!reportsMap.has(report.messageID)) {
      reportsMap.set(report.messageID, report);
    }
  });

  // Retourner un tableau de rapports triés par date décroissante
  return Array.from(reportsMap.values()).sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );
};

const capitalizeFirstLetter = (string) => {
  if (!string) return ''; // Renvoie une chaîne vide si 'string' est undefined ou null
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};  

function formatReportWithBoldTitles(reportText, reportType) {
  const sections = reportType === 'mail' ? parseMailSections(reportText) : parseReportSections(reportText);
  let formattedReport = '';

  if (reportType === 'mail') {
    let isFirstLine = false;
    // Pour les rapports de type "mail", afficher Email et Objet
    if (sections.Email) formattedReport = `<p><strong>Email : </strong> ${sections.Email}</p>`;
    if (sections.Objet) formattedReport += `<p><strong>Objet : </strong> ${sections.Objet}</p>`;

    if (!sections.Email && !sections.Objet) {
      isFirstLine = true; 
    }

    // Formatage du contenu (ou corps pour mail)
    const contentLines = sections.Corps.split('\n');

    contentLines.forEach((line) => {
      if (line.trim() !== '') {
        formattedReport += `<p>${line}</p>`;
      }  else {
        if (isFirstLine) {
          isFirstLine = false;
        } else {
          formattedReport += `<br>`;
        }
      }
    });
    return formattedReport;

  } else {  
    sections.forEach((section, index) => {
      // Format the title in bold if it exists
      if (section.title.trim() !== '') {
        formattedReport += `<p><strong>${section.title}</strong></p>`;
      }
      // Process the section content
      const contentLines = section.content.split('\n');

      contentLines.forEach((line) => {
        if (line.trim() !== '') {
          formattedReport += `<p>${line}</p>`;
        }  else {
          formattedReport += `<br>`;
        }
      });
      
    });
    return formattedReport;
  }
}


function parseReportSections(reportText) {
  const keywords = loadKeywordsFromLocalStorage();
  const lines = reportText.split('\n');
  let sections = [];
  
  let currentSection = { title: '', content: '' };
  let previousLineWasEmpty = true; // Commencer comme vide pour la première ligne
  let hasTitle = false; // Pour suivre si un titre a été trouvé
  let contentBeforeFirstTitle = ''; // Pour accumuler le contenu avant le premier titre

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    const trimmedLine = line.trim();


  
    if (trimmedLine === '') {
      previousLineWasEmpty = true;
      continue;
    }

    let isTitle = false;

    if (previousLineWasEmpty) {
      for (let keyword of keywords) {
        if (trimmedLine.startsWith(keyword) && trimmedLine.length <= 70) {
            isTitle = true;
        } 
        
        // Vérification pour exclure si `:` est présent mais n'est pas à la fin de la ligne
        if (isTitle && trimmedLine.includes(':') && !trimmedLine.endsWith(':')) {
          isTitle = false;
        }
    
        // Sortie de la boucle si un titre valide a été trouvé
        if (isTitle) {
          break;
        }
      }
    }

    if (isTitle) {
      hasTitle = true;
      // Démarrer une nouvelle section
      if (currentSection.content.trim() !== '' || currentSection.title !== '') {
        sections.push(currentSection);
      }
      currentSection = { title: trimmedLine, content: '' };
    } else {
      if (!hasTitle) {
        // Accumuler le contenu avant le premier titre
        contentBeforeFirstTitle += line + '\n';
      } else {
        // Ajouter le contenu à la section actuelle
        if (previousLineWasEmpty) {
          currentSection.content += '\n' + line + '\n';
        } else {  
        currentSection.content += line + '\n';
      }
      }
    }
    previousLineWasEmpty = false;
  }

  // Ajouter la dernière section
  if (currentSection.content.trim() !== '' || currentSection.title !== '') {
    sections.push(currentSection);
  }

  // Gérer le cas où il y a du contenu avant le premier titre
  if (contentBeforeFirstTitle.trim() !== '') {
    sections.unshift({ title: '', content: contentBeforeFirstTitle });
  }

  // Gérer le cas où il n'y a aucun titre
  if (!hasTitle && contentBeforeFirstTitle.trim() === '' && sections.length === 0) {
    // Si aucun titre n'a été trouvé et qu'il n'y a pas de contenu, retourner une section vide
    sections.push({ title: '', content: reportText });
  }

  return sections;
}

function parseMailSections(reportText) {
  const lines = reportText.split('\n');
  let result = {};
  let emailFound = false;
  let objetFound = false;
  let emailValue = '';
  let objetValue = '';
  let bodyLines = [];
  let previousLineWasEmpty = true;
  let hasBodyStarted = false;

  // Parcourir les lignes pour détecter Email, Objet et le corps
  lines.forEach((line) => {
    const trimmedLine = line.trim();
    const lowerCaseLine = trimmedLine.toLowerCase();

    // Identification de l'email
    if (!emailFound && (lowerCaseLine.startsWith('email') || lowerCaseLine.startsWith('mail'))) {
      const emailLine = trimmedLine.split(/[:\s]+/).slice(1).join(' ').trim();
      emailValue = emailLine;
      emailFound = true;

    // Identification de l'objet
    } else if (!objetFound && lowerCaseLine.startsWith('objet')) {
      const objetLine = trimmedLine.split(/[:\s]+/).slice(1).join(' ').trim();
      objetValue = objetLine;
      objetFound = true;

    // Autres lignes qui font partie du corps
    } else {
      if (trimmedLine !== '') {
        hasBodyStarted = true;
        bodyLines.push(line + '\n');
        previousLineWasEmpty = false;
      } else if (hasBodyStarted) {
        // Ajouter une ligne vide pour marquer des sauts de paragraphe dans le corps
        if (!previousLineWasEmpty) {
          bodyLines.push('\n');
        }
        previousLineWasEmpty = true;
      }
    }
  });

  // Ajout de l'email et de l'objet au résultat s'ils sont trouvés
  if (emailFound) {
    result['Email'] = emailValue;
  }
  if (objetFound) {
    result['Objet'] = objetValue;
  }
  // Traitement du corps de l'email pour l'affichage formaté
  let formattedBody = '';
  bodyLines.forEach((line) => {
    if (line === '') {
      formattedBody += `\n`;
    } else {
      formattedBody += `${line}`;
    }
  });

  result['Corps'] = '\n' + formattedBody +  '\n';

  return result;
}


const saveReportsToLocalStorage = (reportsData) => {
  localStorage.setItem('reports', JSON.stringify(reportsData));
};

const loadReportsFromLocalStorage = () => {
  const storedReports = localStorage.getItem('reports');

  return storedReports ? JSON.parse(storedReports) : [];
};

const saveLastRefreshDateToLocalStorage = (date) => {
  localStorage.setItem('lastRefreshDate', date);
};

const loadLastRefreshDateFromLocalStorage = () => {
  const storedDate = localStorage.getItem('lastRefreshDate');
  return storedDate ? new Date(storedDate) : null;
};

const groupReportsByDay = (reports) => {
  return reports.reduce((groups, report) => {
    if (!report || !report.date) {
      console.warn('Report ou report.date est indéfini :', report);
      return groups;
    }
    const reportDate = new Date(report.date);
    const dateKey = isToday(reportDate) ? "Aujourd'hui" : format(reportDate, 'dd/MM/yyyy');
    if (!groups[dateKey]) {
      groups[dateKey] = [];
    }
    groups[dateKey].push(report);
    return groups;
  }, {});
};

const createMailtoLink = (email, subject, body) => {
  // Remplacer les sauts de ligne par %0D%0A pour le format mailto
  const encodedBody = body.replace(/\r?\n/g, '%0D%0A');

  // Créer l'URL mailto avec le sujet et le corps
  return `mailto:${encodeURIComponent(email)}?subject=${encodeURIComponent(
    subject
  )}&body=${encodedBody}`;
};



function ReportList({ isElectron }) {
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [lastReportDate, setLastReportDate] = useState(null);
  const [reportTypes, setReportTypes] = useState([]); 
  const [rawReport, setRawReport] = useState('');
  const [sections, setSections] = useState([]);
  const isApplyingFormatRef = useRef(false);
  const quillRef = useRef(null);


  const [filteredReports, setFilteredReports] = useState([]);
  const [refreshCountdown, setRefreshCountdown] = useState(0);
  const lastReportDateRef = useRef(lastReportDate);

  const isRefreshingRef = useRef(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [reportTypeFilter, setReportTypeFilter] = useState('');


  const [isInsertMode, setIsInsertMode] = useState(false);
  const [currentInsertIndex, setCurrentInsertIndex] = useState(0);
  const [insertionKeys, setInsertionKeys] = useState([]);

  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 950);

  const [showExportOptions, setShowExportOptions] = useState(false);

  
  const [reportContent, setReportContent] = useState('');
 

  const limitPerPage = 50;


  
    // Fonction pour extraire les types de rapport uniques existants dans `reports`
  const extractReportTypes = useCallback(() => {
    const uniqueTypes = Array.from(new Set(reports.map((report) => report.reportType || '')));
    setReportTypes(uniqueTypes);
  }, [reports]);

  const closeExportOptions = () => {
    setShowExportOptions((prev) => {
      console.log("Fermeture des options d'exportation, état précédent :", prev);
      return false;
    });
  };

  const [notification, setNotification] = useState(null);

  const handleMouseEnterSidebarArea = useCallback(() => {
    if (isSmallScreen) {
      setIsSidebarVisible(true);
    }
  }, [isSmallScreen]);

  const handleMouseLeaveSidebar = useCallback(() => {
    if (isSmallScreen) {
      setIsSidebarVisible(false);
    }
  }, [isSmallScreen]);

  const handleSave = useCallback(async () => {
    const updatedReport = {
      ...selectedReport,
      finalReport: rawReport,
    };

    // Update local state and save to localStorage
    setReports((prevReports) => {
      const updatedReports = prevReports.map((report) =>
        report.messageID === selectedReport.messageID ? updatedReport : report
      );
      saveReportsToLocalStorage(updatedReports);
      return updatedReports;
    });

    setSelectedReport(updatedReport);
  }, [rawReport, selectedReport]);


  const handleReportClick = useCallback((report) => {
    setShowExportOptions(false);
    const cleanedFinalReport = report.finalReport.trimStart();
    const reportType = report.reportType || 'consultation';
    setRawReport(formatReportWithBoldTitles(cleanedFinalReport, reportType));
    setSelectedReport(report);
  }, [setSelectedReport, setShowExportOptions]);
  
  
  const handleReportChange = useCallback(
    (newReport) => {
      if (selectedReport) {
        handleSave();
      }
      handleReportClick(newReport);
    },
    [selectedReport, handleSave, handleReportClick]
  );

  const handleEditorChange = useCallback((content, delta, source, editor) => {
    if (isApplyingFormatRef.current) {
      return; // Ne pas continuer si nous sommes en train d'appliquer le formatage
    }
    setRawReport(content);
  }, []);

  const handleReportItemClick = useCallback(
    (report) => {
      handleReportChange(report);
    },
    [handleReportChange]
  );



  const handleArchiveReport = useCallback(
    (messageID) => {
      // Filtrer le rapport à supprimer de l'état
      const updatedReports = reports.filter((report) => report.messageID !== messageID);

      // Mettre à jour l'état
      setReports(updatedReports);

      // Sauvegarder les rapports mis à jour dans le local storage
      saveReportsToLocalStorage(updatedReports);
    },
    [reports]
  );

  const handleSendToServer = useCallback(async () => {
    const chatId = localStorage.getItem('chatId');
    if (!chatId) return;
    await handleSave();

    const reportToSend = copyHtmlToPlainText(selectedReport.finalReport);

    try {
      await axios.post(`${API_BASE_URL}/updateReport`, {
        chatId,
        correctedVersion: reportToSend,
        messageID: selectedReport.messageID,
      });
      setNotification({ message: 'Rapport envoyé avec succès', type: 'success' });
    } catch (error) {
      console.error('Erreur lors de la sauvegarde du rapport:', error);
      alert('Erreur lors de la sauvegarde du rapport');
    }
  }, [handleSave, selectedReport])

  const handleUndo = useCallback(() => {
    handleReportClick(selectedReport);
  }, [selectedReport, handleReportClick]);

  const getReportContent = () => {
    if (!selectedReport) return '';
    return selectedReport.finalReport;
  };

  // Fonction pour créer `reportContent` et ouvrir `ExportOptions`
  const handleExportButtonClick = () => {
    console.log("handleExportButtonClick appelé");
    let generatedReportContent = getReportContent(); // Génération du contenu du rapport
    generatedReportContent = copyHtmlToPlainText(generatedReportContent);
    setReportContent(generatedReportContent);
    setShowExportOptions(true); // Ouvrir les options d'exportation
  };
  const handlePrint = () => {
    const reportContent = formatReportWithBoldTitles(rawReport);
    console.log('Contenu du rapport à imprimer:', reportContent);
  
    // Ouvrir une nouvelle fenêtre pour l'impression
    const printWindow = window.open('', '_blank');
    
    printWindow.document.write(`
      <html>
        <head>
        <title>${capitalizeFirstLetter(selectedReport.name)}</title>
          <style>
            /* Styles pour le contenu imprimé */
            body { font-family: Arial, sans-serif; padding: 20px; }
            h3 { text-align: center; }
            p { margin: 0; padding: 2px 0; } /* Évite les marges excessives entre les paragraphes */
            strong { font-weight: bold; }
          </style>
        </head>
        <body>
          <h3>${capitalizeFirstLetter(selectedReport.name)}</h3>
          <div>${reportContent}</div>
        </body>
      </html>
    `);
  
    printWindow.document.close();
    printWindow.print();
  };
  const handleCopy = useCallback(() => {
      const generatedReportContent = copyHtmlToPlainText(rawReport);

   if (navigator.clipboard) {
      navigator.clipboard
        .writeText(generatedReportContent)
        .then(() => {
          let message = `${capitalizeFirstLetter(selectedReport.reportType)} copié(e) dans le presse-papier (raccourci: Ctrl + Shist + C)`;
          setNotification({ message: message, type: 'info' });
        })
        .catch((err) => {
          console.error('Erreur lors de la copie dans le presse-papier :', err);
        });
    } else {
      console.warn("L'API Clipboard n'est pas disponible.");
    }

  }, [rawReport, selectedReport]);

  
  const handleCopySection = useCallback((text, key, number) => {
    const plainText = copyHtmlToPlainText(text);

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(plainText)
        .then(() => {
          let message = 'copié(e) dans le presse-papier';
          if (key) {
            message = `${key} ${message}`;
          } else {
            message = `Section ${message}`;
          }
          if (number !== undefined) {
            message += ` (raccourci : Ctrl + ${number + 1})`;
          }
          setNotification({ message: message, type: 'info' });
        })
        .catch((err) => {
          console.error('Erreur lors de la copie dans le presse-papier :', err);
        });
    } else {
      console.warn("L'API Clipboard n'est pas disponible.");
    }

    return plainText;
  }, []);

  const sendContentToClipboardAndPaste = useCallback((content) => {
    if (window.electronAPI) {
      window.electronAPI
        .copyAndPaste(content)
        .then(() => {
          setNotification({
            message: 'Le texte a été copié et collé automatiquement.',
            type: 'info',
          });
        })
        .catch((error) => {
          console.error('Erreur lors de la copie et du collage :', error);
        });
    } else {
      console.warn('Electron API is not available');
    }
  }, []);


  const handleRefresh = useCallback(async () => {
    const chatId = localStorage.getItem('chatId');
    if (!chatId) return;

    if (isRefreshingRef.current) {
      console.log('Veuillez attendre avant de rafraîchir à nouveau.');
      return;
    }

    isRefreshingRef.current = true;
    setRefreshCountdown(5);

    let dateToUse = lastReportDateRef.current;

    if (!dateToUse) {
      const storedLastRefreshDate = loadLastRefreshDateFromLocalStorage();
      if (storedLastRefreshDate) {
        setLastReportDate(storedLastRefreshDate);
        dateToUse = storedLastRefreshDate;
      } else {
        return;
      }
    }

    setLoading(true);

    try {
      const response = await axios.post(`${API_BASE_URL}/api/refreshReports`, {
        chatId,
        lastReportDate: dateToUse,
      });

      if (response.data.length > 0) {
        const reportsData = response.data;

        // Fusionner les nouveaux rapports transformés avec les rapports existants
        const existingReports = loadReportsFromLocalStorage();
        const combinedReports = mergeReports(existingReports, reportsData);

        setReports(combinedReports);
        saveReportsToLocalStorage(combinedReports);

        const latestDate = getLatestReportDate(reportsData);

        // Mise à jour de lastReportDate
        setLastReportDate(latestDate);
        saveLastRefreshDateToLocalStorage(latestDate.toISOString());

        // Mettre à jour hasMore
        setHasMore(response.data.length >= limitPerPage);

        const message = response.data.length + ' nouveau(x) rapport(s)';
        setNotification({ message, type: 'success' });

        extractReportTypes(response.data);
      } else {
        setNotification({ message: 'Vos rapports sont à jour.', type: 'classic' });
        setHasMore(false);
      }
    } catch (error) {
      console.error('Erreur lors du rafraîchissement des rapports:', error);
    } finally {
      setLoading(false);
    }
  }, [limitPerPage, extractReportTypes]);

  const handleKeyDown = useCallback(
    (e) => {
      if (!selectedReport) return;
      
      // Handle Ctrl + number shortcuts
      if (e.ctrlKey && !e.shiftKey) {
        const sections = parseReportSections(htmlToPlainText(rawReport));
        const numberKey = parseInt(e.key);

        if (numberKey >= 1 && numberKey <= 9) {
          e.preventDefault();
          const index = numberKey - 1;

          console.log(sections[index])
          console.log(sections)

          if (sections[index]) {
            console.log("ici: ", e)
            const content = handleCopySection(sections[index].content, sections[index].title, index);
            sendContentToClipboardAndPaste(content);
          }
        }
      }

      // Gestion des raccourcis Ctrl+Shift
      if (e.ctrlKey && e.shiftKey) {
        switch (e.key.toUpperCase()) {
          case 'S':
            e.preventDefault();
            handleSendToServer();
            break;
          case 'Z':
            e.preventDefault();
            handleUndo();
            break;
          case 'C':
            e.preventDefault();
            handleCopy();
            break;
          case 'R':
            e.preventDefault();
            handleRefresh();
            break;
          default:
            break;
        }
      }
    },
    [
      selectedReport,
      rawReport,
      handleCopySection,
      sendContentToClipboardAndPaste,
      handleSendToServer,
      handleUndo,
      handleCopy,
      handleRefresh,
    ]
  );

  useEffect(() => {
    if (quillRef.current && sections.length > 0) {
      const quill = quillRef.current.getEditor();
      isApplyingFormatRef.current = true; // Début de l'application du formatage
  
      // Supprimer tous les formats de gras existants
      quill.formatText(0, quill.getLength(), 'bold', false);
  
      // Parcourir les sections pour appliquer le formatage
      let cursorPosition = 0;
      const fullText = quill.getText();
  
      sections.forEach((section) => {
        const title = section.title;
  
        // Rechercher le titre à partir de la position actuelle du curseur
        const titleIndex = fullText.indexOf(title, cursorPosition);
  
        if (titleIndex !== -1) {
          // Appliquer le gras au titre
          quill.formatText(titleIndex, title.length, 'bold', true);
          cursorPosition = titleIndex + title.length;
        }
      });
  
      isApplyingFormatRef.current = false; // Fin de l'application du formatage
    }
  }, [sections]);
  
  useEffect(() => {
    const newSections = parseReportSections(htmlToPlainText(rawReport));
    setSections(newSections);
  }, [rawReport]);

useEffect(() => {
  const handleResize = () => {
    const isSmall = window.innerWidth < 950;
    setIsSmallScreen(isSmall);

    if (!isSmall) {
      setIsSidebarVisible(true);
    } else if (!selectedReport) {
      setIsSidebarVisible(true);
    } else {
      setIsSidebarVisible(false);
    }
  };

  window.addEventListener('resize', handleResize);

  // Appeler handleResize une fois lors du montage initial
  handleResize();

  return () => window.removeEventListener('resize', handleResize);
}, [selectedReport]);

  // Synchroniser lastReportDateRef avec lastReportDate
  useEffect(() => {
    lastReportDateRef.current = lastReportDate;
  }, [lastReportDate]);


const handleGlobalShortcut = useCallback((numberKey) => {
  if (!selectedReport) return; // Vérifiez la valeur actuelle

  const index = numberKey - 1;

  const reportForInsertion = parseReportSections(htmlToPlainText(rawReport));

  // Récupère le titre et le contenu de la section
  const title = reportForInsertion[index]?.title;

  if (!title) {
    console.warn(`Section non trouvée pour la clé : ${currentInsertIndex}`);
    return;
  }
  const content = reportForInsertion[index]?.content;

  if (content) {
      handleCopySection(content, title, index);
      sendContentToClipboardAndPaste(content);
  }
}, [selectedReport, rawReport, sendContentToClipboardAndPaste, handleCopySection]);


const activateInsertMode = useCallback(() => {
  if (!selectedReport) {
    if (window.electronAPI) {
      window.electronAPI.sendToFloatingWindow({ 
        mode: 'normal',
       });
    }
    return;
  }

  let reportForInsertion = parseReportSections(htmlToPlainText(rawReport));

  const titles = Object.values(reportForInsertion).map(section => section.title);

  if (titles.length === 0) {
    setNotification({ message: 'Aucune section disponible pour l\'insertion.', type: 'warning' });
    return;
  }

  if (titles[0] === '') {
    titles[0] = 'Début'
  }

  setIsInsertMode(true);
  setInsertionKeys(titles);
  setCurrentInsertIndex(0);

  if (window.electronAPI) {
    window.electronAPI.sendToFloatingWindow({
      mode: 'insertion',
      currentKey: titles[0],
      titles: titles,
      name: selectedReport.name,
    });
  }
}, [selectedReport, rawReport]);

const deactivateInsertMode = useCallback(() => {
  setIsInsertMode(false);
  setInsertionKeys([]);
  setCurrentInsertIndex(0);

  // Send information to reducedWindow to update display
  if (window.electronAPI) {
    window.electronAPI.sendToFloatingWindow({ 
      mode: 'normal'
     });

  }
}, []);

const moveToNextSection = useCallback(() => {
  const nextIndex = (currentInsertIndex + 1) % insertionKeys.length;
  setCurrentInsertIndex(nextIndex);
  const nextKey = insertionKeys[nextIndex];
  if (window.electronAPI) {
    window.electronAPI.sendToFloatingWindow({
      currentKey: nextKey,
    });
  }
}, [currentInsertIndex, insertionKeys]);

const moveToPreviousSection = useCallback(() => {
  const prevIndex = (currentInsertIndex - 1 + insertionKeys.length) % insertionKeys.length;
  setCurrentInsertIndex(prevIndex);
  const prevKey = insertionKeys[prevIndex];
  if (window.electronAPI) {
    window.electronAPI.sendToFloatingWindow({
      currentKey: prevKey,
    });
  }
}, [currentInsertIndex, insertionKeys]);


const insertCurrentSection = useCallback(() => {

  const reportForInsertion = parseReportSections(htmlToPlainText(rawReport));

  // Récupère le titre et le contenu de la section
  const section = reportForInsertion[currentInsertIndex];
  if (!section) {
    console.warn(`Section non trouvée pour la clé : ${currentInsertIndex}`);
    return;
  }

  const content = section.content;

  sendContentToClipboardAndPaste(content);

  moveToNextSection();

}, [currentInsertIndex, insertionKeys, rawReport, sendContentToClipboardAndPaste, moveToNextSection]);



const handleReportSectionSelected = useCallback(
  (sectionName) => {
    console.log(`ReactJS Section sélectionnée : ${sectionName}`);
    
    // Trouver l'index de la clé dans insertionKeys
    const sectionIndex = insertionKeys.indexOf(sectionName);

    if (sectionIndex !== -1) {
       console.log(`Index de la section : ${sectionIndex}`);
      setCurrentInsertIndex(sectionIndex);
      if (window.electronAPI) {
        window.electronAPI.sendToFloatingWindow({
          currentKey: sectionName,
        });
      }
    } else {
      console.warn(`La clé "${sectionName}" n'a pas été trouvée dans insertionKeys.`);
    }
  },
  [insertionKeys] // Dépendance pour recalculer si insertionKeys change
);

  // Écouter l'événement 'report-section-selected' lorsque le composant est monté
  useEffect(() => {
    if (window.electronAPI && window.electronAPI.onReportSectionSelected) {
      const unsubscribe = window.electronAPI.onReportSectionSelected(handleReportSectionSelected);
      return () => {
        if (typeof unsubscribe === 'function') {
          unsubscribe();
        }
      };
    }
  }, [handleReportSectionSelected]);


const handleInsertionShortcut = useCallback((key) => {
  
  if (!isInsertMode) {
    return;}
console.log('handleInsertionShortcut appelé avec:', key);
  if (key === 'Up') {
    moveToPreviousSection();
  } else if (key === 'Down') {
    moveToNextSection();
  } else if (key === 'Right') {
    insertCurrentSection();
  }
}, [isInsertMode, moveToNextSection, moveToPreviousSection, insertCurrentSection]);

useEffect(() => {
  const handleBeforeUnload = (event) => {
    const startSave = async () => {
      await handleSave();
    };
    startSave();
    setTimeout(() => {
      // Après le délai, la page se ferme automatiquement
    }, 1000); // Délai de 1 seconde
  };

  window.addEventListener('beforeunload', handleBeforeUnload);

  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };
}, [handleSave]);

// Ajouter un événement global pour capturer les touches
useEffect(() => {
  window.addEventListener('keydown', handleKeyDown);
  return () => {
    window.removeEventListener('keydown', handleKeyDown);
  };
}, [handleKeyDown]);

useEffect(() => {
  window.addEventListener('keydown', handleInsertionShortcut);
  return () => {
    window.removeEventListener('keydown', handleInsertionShortcut);
  };
}, [handleInsertionShortcut]);


  useEffect(() => {
    if (refreshCountdown > 0) {
      const timerId = setTimeout(() => {
        setRefreshCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    } else {
      isRefreshingRef.current = false;
    }
  }, [refreshCountdown]);

  const fetchInitialReports = useCallback(async () => {
    const chatId = localStorage.getItem('chatId');
    if (!chatId) return;

    setLoading(true);

    try {
      const response = await axios.post(`${API_BASE_URL}/api/fetchInitialReports`, {
        chatId,
        limit: limitPerPage,
      });

      if (response.data.length === 0) {
        setHasMore(false);
      } else {
        const reportsData = response.data;

        setReports(reportsData);
        saveReportsToLocalStorage(reportsData);

        const latestDate = getLatestReportDate(reportsData);

        // Mise à jour de lastReportDate
        setLastReportDate(latestDate);
        saveLastRefreshDateToLocalStorage(latestDate.toISOString());
        console.log('Last Report Date:', latestDate);
        // Mettre à jour hasMore
        setHasMore(response.data.length >= limitPerPage);

        extractReportTypes(response.data);
      }
    } catch (error) {
      console.error('Error fetching initial reports:', error);
    } finally {
      setLoading(false);
    }
  }, [limitPerPage,  extractReportTypes]);

  const loadMoreReports = useCallback(async () => {
    const chatId = localStorage.getItem('chatId');
    if (!chatId || loading || !hasMore) return;

    setLoading(true);

    try {
      // Obtenir la date du rapport le plus ancien
      const oldestReportDate = getOldestReportDate(reports);

      const response = await axios.post(`${API_BASE_URL}/api/loadMoreReports`, {
        chatId,
        lastReportDate: oldestReportDate,
        limit: limitPerPage,
      });

      if (response.data.length === 0) {
        setHasMore(false);
      } else {
        const reportsData = response.data;

        // Fusionner les nouveaux rapports transformés avec les rapports existants
        const combinedReports = mergeReports(reports, reportsData);

        setReports(combinedReports);
        saveReportsToLocalStorage(combinedReports);

        // Mettre à jour hasMore en fonction du nombre de rapports reçus
        setHasMore(response.data.length >= limitPerPage);
      }
    } catch (error) {
      console.error('Error loading more reports:', error);
    } finally {
      setLoading(false);
    }
  }, [hasMore, loading, limitPerPage, reports]);

  
  /*
  // floating windows update when selectedReport changes
  useEffect(() => {
    if (selectedReport) {
      let reportForFloatingWindow = parseReportSections(htmlToPlainText(selectedReport.finalReport));
      // Vérification si ipcRenderer est disponible avant d'envoyer les données
      if (reportForFloatingWindow && window.electronAPI && window.electronAPI.reportSelected) {
        window.electronAPI.reportSelected({
          name: selectedReport.name,
          finalReport: reportForFloatingWindow,
          messageID: selectedReport.messageID,
          date: selectedReport.date
        });
        console.log('Report sent to reduced window:', {
          name: selectedReport.name,
          finalReport: reportForFloatingWindow,
          messageID: selectedReport.messageID,
          date: selectedReport.date
        });
      }      
    }
  }, [selectedReport, rawReport]);
  */

  //Pour eviter duplication des keys
  /*
  useEffect(() => {
  if (selectedReport) {
    currentReportIdRef.current = selectedReport.messageID;
  }
}, [selectedReport]);
  */

  /* a enlever après 
  useEffect(() => {
    console.log('Raw report updated:', rawReport);
  }, [rawReport]);
  
  useEffect(() => {
    console.log('Structured report updated:', structuredReport);
  }, [structuredReport]);  
  */
  
  
  
useEffect(() => {
    // Au chargement du composant, vérifier si des rapports sont stockés
    const storedReports = loadReportsFromLocalStorage();
    const storedLastRefreshDate = loadLastRefreshDateFromLocalStorage();

    if (storedReports.length > 0 && storedLastRefreshDate) {
      // Si des rapports sont stockés, les charger et définir lastReportDate
      setReports(storedReports);
      setLastReportDate(storedLastRefreshDate);
      handleRefresh();
    } else {
      // Sinon, appeler fetchInitialReports pour charger les rapports initiaux
      fetchInitialReports();
    }
  }, []);

  useEffect(() => {
    // Filtrer les rapports en fonction de la recherche et du filtre
    let filtered = reports;

    if (searchQuery) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      filtered = filtered.filter(
        (report) =>
          report.name.toLowerCase().includes(lowerCaseQuery) ||
          report.finalReport.toLowerCase().includes(lowerCaseQuery)
      );
    }

    if (reportTypeFilter) {
      filtered = filtered.filter((report) => report.reportType === reportTypeFilter);
    }

    setFilteredReports(filtered);
  }, [reports, searchQuery, reportTypeFilter]);

  const getFirstThreeLines = (text, reportType) => {
    if (!text) return '';
  
    // Enlever tout ce qui est entre les balises HTML
    let plainText = text
    .replace(/<[^>]*>/g, ' ') // Remplacer les balises HTML par des espaces
    .replace(/{"|}"|":"|","|}/g, ' ');  // Remplacer {" , }", ":" par des espaces


      return plainText.slice(0, 80) + '...';
  };



  function copyHtmlToPlainText(html) {
    // Crée un élément temporaire pour manipuler le HTML
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;

  // Remplacer les balises <p> par des sauts de ligne (\n), en ajoutant un saut de ligne avant et après
  tempElement.querySelectorAll('p').forEach(p => {
    p.replaceWith(`${p.innerText}\n`);
  });
  
    // Extraire le texte brut du contenu HTML
    const plainText = tempElement.innerText;
  
    // Supprime les espaces superflus en début et fin de chaîne
    return plainText.trim();
  }

  function htmlToPlainText(html) {
    // Crée un élément temporaire pour manipuler le HTML
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;

    // Remplacer les balises <br> par des sauts de ligne (\n)
    tempElement.querySelectorAll('br').forEach(br => {
      br.replaceWith('\n');
    });
    
  // Remplacer les balises <p> par des sauts de ligne (\n), en ajoutant un saut de ligne avant et après
  tempElement.querySelectorAll('p').forEach(p => {
    p.replaceWith(`\n${p.innerText}\n`);
  });
  
    // Extraire le texte brut du contenu HTML
    const plainText = tempElement.innerText;
  
    // Supprime les espaces superflus en début et fin de chaîne
    return plainText.trim();
  }
  

useEffect(() => {
  if (window.electronAPI && window.electronAPI.onGlobalShortcut) {
    const removeListener = window.electronAPI.onGlobalShortcut((numberKey) => {
      handleGlobalShortcut(numberKey);
    });
    return () => {
      if (typeof removeListener === 'function') {
        removeListener();
      }
    };
  }
}, [handleGlobalShortcut]);


useEffect(() => {
  if (window.electronAPI) {
  async function fetchSelectedReport() {
    const report = await window.electronAPI.requestSelectedReport();
    if (report) {
      setSelectedReport(report);
    }
  }
  fetchSelectedReport();
}
}, []);

useEffect(() => {
  if (window.electronAPI) {
  window.electronAPI.onCheckRendererReady(() => {
    window.electronAPI.sendRendererReady();
  });

  window.electronAPI.onUpdateReport((reportData) => {
    setSelectedReport(reportData);
  });

  async function fetchSelectedReport() {
    const report = await window.electronAPI.requestSelectedReport();
    if (report) {
      setSelectedReport(report);
    }
  }
  fetchSelectedReport();
}
}, []);

useEffect(() => {
  if (window.electronAPI) {
  window.electronAPI.sendRendererReady();
  }
}, []);

useEffect(() => {
  if (window.electronAPI && window.electronAPI.onUpdateInsertMode) {
    const unsubscribe = window.electronAPI.onUpdateInsertMode((data) => {
      if (data.mode === 'insertion') {
        activateInsertMode();
      } else {
        deactivateInsertMode();
      }
    });
    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  } else {
    console.log('window.electronAPI.onUpdateInsertMode is not available');
  }
}, [activateInsertMode, deactivateInsertMode]);

 // Handle insertion shortcuts received from main.js
 useEffect(() => {
  if (window.electronAPI && window.electronAPI.onInsertionShortcut) {
    const unsubscribe = window.electronAPI.onInsertionShortcut((key) => {
      handleInsertionShortcut(key);
    });
    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }
}, [handleInsertionShortcut]);


  
  const groupedReports = groupReportsByDay(filteredReports);

  const getMailtoLink = () => {
    // Utiliser parseMailSections pour structurer l'email
    const structuredEmail = parseMailSections(copyHtmlToPlainText(rawReport));
    const email = structuredEmail.Email || '';
    const subject = htmlToPlainText(structuredEmail.Objet || '');
    const body = htmlToPlainText(structuredEmail.Corps || '');
  
    return createMailtoLink(email, subject, body);
  };

    // Filtrer les rapports en fonction du type sélectionné et de la recherche
useEffect(() => {
  let filtered = reports;
  if (reportTypeFilter) {
    filtered = filtered.filter((report) => report.reportType === reportTypeFilter);
  }
  setFilteredReports(filtered);
}, [reports, reportTypeFilter]);

  // Utilisation de l'API Electron pour écouter l'événement 'select-report'
  useEffect(() => {
    if (window.electronAPI && window.electronAPI.onSelectReport) {
      window.electronAPI.onSelectReport((reportData) => {
        handleReportClick(reportData);
      });
    }
  }, [handleReportClick]);

/* a enlever si tout fonctionne
 useEffect(() => {
     if (window.electronAPI && window.electronAPI.onUpdateSectionContent) {
       window.electronAPI.onUpdateSectionContent((sectionKey, updatedContent) => {
         setStructuredReport((prevReport) => {
           return {
             ...prevReport,
             [sectionKey]: updatedContent,
           };
         });
       });
     }
   }, []);
   */

   return (
    <div className="report-list-container">
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}
  
      {/* Zone de survol pour afficher la barre latérale sur petits écrans */}
      {isSmallScreen && (!isSidebarVisible || !selectedReport) && (
        <div
          className="sidebar-hover-area"
          onMouseEnter={handleMouseEnterSidebarArea}
        ></div>
      )}
  
      {/* Barre latérale */}
      <div
        className={`sidebar-container ${isSidebarVisible || !selectedReport ? '' : 'collapsed'} ${
          !isElectron ? 'web-mode' : ''
        }`}
        onMouseLeave={handleMouseLeaveSidebar}
      >
        <div className="sidebar-fixed">
          {/* Bouton de rafraîchissement */}
          <div className="refresh-button-container">
            <Button variant="primary" onClick={handleRefresh} disabled={loading}>
              <FaSync /> Rafraîchir les rapports
            </Button>
          </div>
  
{/* Barre de recherche et filtre */}
<div className="filter-container">
        <input
          type="text"
          placeholder="Rechercher..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <select
          value={reportTypeFilter}
          onClick={extractReportTypes} // Extraire les types de rapport disponibles uniquement lors du clic
          onChange={(e) => setReportTypeFilter(e.target.value)}
        >
          <option value="">Tous les types de rapport</option>
          {reportTypes.map((type) => (
            <option key={type} value={type}>{capitalizeFirstLetter(type)}</option>
          ))}
        </select>
        {(searchQuery || reportTypeFilter) && (
          <button
            onClick={() => {
              setSearchQuery('');
              setReportTypeFilter('');
            }}
          >
            Réinitialiser les filtres
          </button>
        )}
      </div>
      </div>
        {/* Liste des rapports avec scroll */}
        <div className="report-list-scrollable">
          {Object.keys(groupedReports).map((dateKey, idx) => (
            <div key={idx} className="report-group">
              <h3 className="report-group-title">{dateKey}</h3>
              <ul>
                {groupedReports[dateKey].map((report) => (
                  <li
                    key={report.messageID}
                    className="report-item"
                    onClick={() => handleReportItemClick(report)}
                  >
                    <h3>{capitalizeFirstLetter(report.name)}</h3>
                    <p className="report-preview">
                      {getFirstThreeLines(report.finalReport, report.reportType)}
                    </p>
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // Empêche le clic de sélectionner le rapport
                        handleArchiveReport(report.messageID);
                      }}
                      className="archive-button"
                    >
                      <FaArchive /> Archiver
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          {filteredReports.length === 0 && <p>Aucun rapport disponible</p>}
          {loading && <p>Chargement des rapports...</p>}
          {hasMore && (
            <Button variant="secondary" onClick={loadMoreReports}>
              Charger 50 rapports de plus...
            </Button>
          )} 
        </div>
      </div>
  
      {/* Contenu principal */}
      <div className={`main-content ${isSidebarVisible ? '' : 'full-width collapsed-sidebar'}`}>
        {showExportOptions ? (
          <ExportOptions
          onExportPDF={(options) => exportToPDF({ ...options, reportContent })}
          onExportWord={(options) => exportToWord({ ...options, reportContent })}
          onClose={closeExportOptions}
          reportContent={reportContent} // Passer le reportContent en tant que prop
          />
        ) : selectedReport ? (
          <div className="report-details">
            <div className="report-header">
              <h2 className="report-title">{capitalizeFirstLetter(selectedReport.name)}</h2>
            </div>
            <div className="buttons-container">
  <Button variant="success" onClick={handleSendToServer}>
    <FaBrain /> Améliorer votre IA
  </Button>
  <Button variant="warning" onClick={handleUndo}>
    <FaUndo /> Annuler
  </Button>
  {selectedReport?.reportType === 'mail' ? (
    <a href={getMailtoLink()}>
      <Button variant="mail">
        <FaEnvelope /> Envoyer par e-mail
      </Button>
    </a>
  ) : (
    <>
      <Button variant="print" onClick={handlePrint}>
        <FaPrint /> Imprimer
      </Button>
      <Button variant = "word" onClick={handleExportButtonClick}>
        <FaFileWord/> Exporter en Word
      </Button>
    </>
  )}
</div>
<div className="separator-horizontal"></div>
{/* Boutons de copie de section avec le bouton principal "Copier" en début de ligne */}
<div className="handlecopysection-scrollable">
  <div className="handlecopysection-buttons">
    <Button variant="info" onClick={handleCopy}>
      <FaCopy /> Copier le rapport
    </Button>

    {/* Afficher les boutons de section uniquement si le reportType n'est pas "mail" */}
    {selectedReport?.reportType !== 'mail' && sections.map((section, index) => (
      <Button 
        className="handlecopysection-button"
        key={index}
        variant="info"
        onClick={() => handleCopySection(section.content, sections.length > 1 && section.title.trim() === '' ? "Début" : section.title, index)}
      >
        <div className="button-header">
          <FaCopy /> <span className="section-index">{index + 1}</span>
        </div>
        {/* Si le titre est vide et qu'il y a plus d'une section, affiche "Début" ; sinon, affiche le titre avec une limite de 20 caractères */}
        <div className="section-title">
          {sections.length > 1 && section.title.trim() === '' ? 'Début' : (section.title.length > 20 ? section.title.slice(0, 20) + '…' : section.title)}
        </div>
      </Button>
    ))}
  </div>
</div>
<CustomReactQuill
  ref={quillRef}
  value={rawReport}
  onChange={handleEditorChange}
  modules={quillModules}
  formats={['bold', 'italic', 'underline', 'link']}
  theme="snow"
/>
    </div>
        ) : (
          <div className="report-details-placeholder">
            <p>
              <FaFileMedical /> Sélectionnez un rapport dans la barre latérale.
            </p>
          </div>
        )}
        {/* Bande grise avec flèche lorsque la barre latérale est masquée */}
        {!isSidebarVisible && (
          <div className="collapsed-sidebar-indicator" onMouseEnter={handleMouseEnterSidebarArea}>
            <FaChevronRight className="arrow-icon" />
          </div>
        )}
      </div>
    </div>
  );
}

export default ReportList;