// Settings.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Settings.css';
import Button from './Button';
import LanguagesList from './LanguagesList';
import { FaStethoscope, FaChevronLeft , FaSave } from 'react-icons/fa';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


function Settings() {
  const [username, setUsername] = useState('Guest');
  const [email, setEmail] = useState('');
  const [emailToggle, setEmailToggle] = useState(false);
  const [vocabulary, setVocabulary] = useState([]);
  const [newVocab, setNewVocab] = useState('');
  const [selectedVocabulary, setSelectedVocabulary] = useState('');
  const [reportLanguage, setReportLanguage] = useState('français');
  const [dictationLanguage, setDictationLanguage] = useState('fr');
  const [hasChanges, setHasChanges] = useState(false);
  const [keywords, setKeywords] = useState([]); // Initialisation vide, sans valeurs par défaut
  const [showKeywords, setShowKeywords] = useState(false);
  const [newKeyword, setNewKeyword] = useState('');

  const isRefreshingRef = useRef(false);



 // Liste des raccourcis clavier
 const shortcuts = [
  { key: 'Ctrl + 1', action: 'Copier la première section' },
  { key: 'Ctrl + 2', action: 'Copier la deuxième section' },
  { key: 'Ctrl + 3', action: 'Copier la troisième section' },
  { key: 'Ctrl + ... 9', action: 'Copier la X ème section' },
  { key: 'Ctrl + Shift + C', action: 'Copier le rapport entier' },
  { key: 'Ctrl + Shift + Z', action: 'Annuler les modifications' },
  { key: 'Ctrl + Shift + S', action: 'Améliorer votre IA avec le rapport' },
  { key: 'Ctrl + Shift + R', action: 'Rafraîchir les rapports' },
];


const handleAddKeyword = () => {
  if (newKeyword && !keywords.includes(newKeyword.trim())) {
      const updatedKeywords = [...keywords, newKeyword.trim()];
      setKeywords(updatedKeywords);
      setNewKeyword('');
      setHasChanges(true);
      localStorage.setItem('keywords', updatedKeywords.join(', ')); // Mettre à jour localStorage
  }
};

const handleRemoveKeyword = (keywordToRemove) => {
  const updatedKeywords = keywords.filter(keyword => keyword !== keywordToRemove);
  setKeywords(updatedKeywords);
  setHasChanges(true);
  localStorage.setItem('keywords', updatedKeywords.join(', ')); // Mettre à jour localStorage
};

const handleCancel = () => {
  window.location.reload(); // Recharge la page pour annuler les modifications
};


useEffect(() => {
  // Charger les réglages depuis le serveur
  const fetchSettings = async () => {
      try {
          const chatId = localStorage.getItem('chatId');
          const response = await axios.post(`${API_BASE_URL}/getSettings`, { chatId });
          const data = response.data;

          setUsername(data.name || 'Guest');
          setEmail(data.email || '');
          setEmailToggle(Boolean(data.emailenabled));
          setVocabulary(data.voc ? data.voc.split(',').map(word => word.trim()).filter(word => word) : []);
          setReportLanguage(data.langue || 'français');
          setDictationLanguage(data.french || 'fr');
      } catch (error) {
          console.error('Error fetching settings:', error);
      }
  };

  fetchSettings();

  // Charger les mots-clés depuis le localStorage ou utiliser les mots-clés par défaut
  const storedKeywords = localStorage.getItem('keywords');
  setKeywords(storedKeywords.split(',').map(word => word.trim()));
}, []);


  
const handleSave = async () => {
  try {
      const chatId = localStorage.getItem('chatId');
      const vocabularyString = vocabulary.join(', ');
      const keywordsString = keywords.join(', ');

      const response = await axios.post(`${API_BASE_URL}/updateSettings`, {
          chatId,
          email,
          emailenabled: emailToggle,
          vocabulary: vocabularyString,
          french: dictationLanguage,
          langue: reportLanguage
      });

      if (response.status === 200) {
          alert('Réglages sauvegardés avec succès');
          setHasChanges(false);
          localStorage.setItem('keywords', keywordsString); // Enregistrer les mots-clés dans localStorage
      }
  } catch (error) {
      console.error('Erreur lors de la sauvegarde des réglages:', error);
      alert('Erreur lors de la sauvegarde des réglages');
  }
};

    const handleToggleChange = (setter) => (e) => {
        setter(e.target.checked);
        setHasChanges(true);
    };

    const handleInputChange = (setter) => (e) => {
        setter(e.target.value);
        setHasChanges(true);
    };

    const handleAddVocabulary = () => {
        if (newVocab && !vocabulary.includes(newVocab.trim())) {
            setVocabulary([...vocabulary, newVocab.trim()]);
            setNewVocab('');
            setHasChanges(true);
        }
    };

    const handleRemoveVocabulary = () => {
        const updatedVocabulary = vocabulary.filter(vocab => vocab !== selectedVocabulary);
        setVocabulary(updatedVocabulary);
        setSelectedVocabulary('');
        setHasChanges(true);
    };

    return (
        <div className="settings-container">
          <section>
            <h3>
              <FaStethoscope className="icon" /> Compte
            </h3>
            <p>Utilisateur: {username}</p>
            <div className="form-group">
              <label htmlFor="email" className="form-label">
                E-mail:
                <input
                id="email"
                type="email"
                className="form-input"
                value={email}
                onChange={handleInputChange(setEmail)}
                placeholder="Entrez votre e-mail"
              />
               <label className="toggle-label">
               Recevoir les rapports par e-mail :
                <input
                  type="checkbox"
                  checked={emailToggle}
                  onChange={handleToggleChange(setEmailToggle)}
                />
              </label>
              </label>
            </div>
          </section>
    
          <section>
            <h3> Vocabulaire</h3>
            <p>
            📄 Liste de vocabulaire:{' '}
              {vocabulary.length > 0 ? vocabulary.join(', ') : 'Vide'}
            </p>
            <div className="vocabulary-input">
              <input
                type="text"
                placeholder="Ajouter du vocabulaire..."
                value={newVocab}
                onChange={(e) => setNewVocab(e.target.value)}
              />
              <Button 
              variant='success' 
                onClick={handleAddVocabulary}
                disabled={!newVocab.trim()}
              >
                Ajouter
              </Button>
            </div>
            <div className="vocabulary-controls">
              <select
                value={selectedVocabulary}
                onChange={handleInputChange(setSelectedVocabulary)}
              >
                <option value="">Choisir un vocabulaire à supprimer</option>
                {vocabulary.map((vocab) => (
                  <option key={vocab} value={vocab}>
                    {vocab}
                  </option>
                ))}
              </select>
              <Button variant='danger'
                disabled={!selectedVocabulary}
                onClick={handleRemoveVocabulary}
              >
                Supprimer
              </Button>
            </div>
          </section>

         
          <section>
            <h3>Langue</h3>
            <label className="input-label">
            📄 Langue des rapports:
              <select
                value={reportLanguage}
                onChange={handleInputChange(setReportLanguage)}
              >
                {Object.keys(LanguagesList.languages).map((lang) => (
                  <option key={lang} value={LanguagesList.languages[lang]}>
                    {lang}
                  </option>
                ))}
              </select>
            </label>
            <label className="input-label">
            🎙️ Langue de dictée:
              <select
                value={dictationLanguage}
                onChange={handleInputChange(setDictationLanguage)}
              >
                {Object.keys(LanguagesList.languages).map((lang) => (
                  <option key={lang} value={LanguagesList.languages[lang]}>
                    {lang}
                  </option>
                ))}
              </select>
            </label>
          </section>

          <section>
  <h3>Raccourcis clavier</h3>
  <div className="shortcut-list">
    {shortcuts.map((shortcut, index) => (
      <li key={index}>
        <span className="key-icon">{shortcut.key}</span> : {shortcut.action}
      </li>
    ))}
  </div>
</section>

           {/* Fenêtre modale des mots-clés */}
           <section>
  <h3>📋 Mots-clés pour les sections des rapports</h3>
  <p>
    Les mots-clés sont utilisés pour séparer vos rapports en sections. Ajoutez un mot-clé si vous souhaitez une nouvelle section personnalisée dans vos rapports.
  </p>
  <div className='add-keyword-section'><input
        type="text"
        placeholder="Ajouter un mot-clé..."
        value={newKeyword}
        onChange={(e) => setNewKeyword(e.target.value)}
      />
      <Button variant = 'success' onClick={handleAddKeyword} disabled={!newKeyword.trim()}>
        Ajouter
      </Button>
      </div>
  <Button variant = 'primary' onClick={() => setShowKeywords((prev) => !prev)}>
    {showKeywords ? "Masquer les mots-clés existants" : "Afficher les mots-clés existants"}
  </Button>
  {showKeywords && (
    <div className="keywords-section">
      <h4>Liste des mots-clés</h4>
      <ul>
        {keywords.map((keyword) => (
          <li key={keyword}>
            {keyword}
            <Button variant = 'danger' onClick={() => handleRemoveKeyword(keyword)}>Supprimer</Button>
          </li>
        ))}
      </ul>
    </div>
  )}
</section>
          
          {/* Barre fixe du bouton de sauvegarde */}
          <div className="save-button-bar">
          <Button
               variant='secondary'
               disabled={!hasChanges}
                onClick={handleCancel}
            >
                <FaChevronLeft /> Retour
            </Button>

            <Button
              variant='primary'
              disabled={!hasChanges}
              onClick={handleSave}
            >
              <FaSave /> Sauvegarder les réglages
            </Button>
          </div>
        </div>
      );
    }
    
    export default Settings;