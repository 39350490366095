// CustomReactQuill.js
import React, { useRef, useEffect, forwardRef } from 'react';
import ReactQuill from 'react-quill';
import Quill from 'quill';

const CustomReactQuill = forwardRef(({ value, onChange, modules, formats, theme }, ref) => {
  // Utilisez le ref passé depuis le parent, sinon créez-en un nouveau pour l'utilisation interne
  const quillRef = ref;

  // Personnaliser Quill pour utiliser <div> au lieu de <p>
  useEffect(() => {
    const Block = Quill.import('blots/block');
    Block.tagName = 'div'; // Utiliser <div> au lieu de <p>
    Quill.register(Block, true);
  }, []);

  useEffect(() => {
    if (quillRef.current && !quillRef.current.quillRefInitialized) {
      const quill = quillRef.current.getEditor();
      quill.root.addEventListener('copy', (e) => {
        console.log('onCopy event triggered (native)');
        const range = quill.getSelection();
        if (range) {
          const text = quill.getText(range.index, range.length);
          e.clipboardData.setData('text/plain', text);
          e.preventDefault();
          console.log('Copied text:', text);
        }
      });
      quill.root.addEventListener('cut', (e) => {
        console.log('onCut event triggered (native)');
        const range = quill.getSelection();
        if (range) {
          const text = quill.getText(range.index, range.length);
          e.clipboardData.setData('text/plain', text);
          quill.deleteText(range.index, range.length);
          e.preventDefault();
          console.log('Cut text:', text);
        }
      });
      quillRef.current.quillRefInitialized = true; // Marquer comme initialisé
    }
  }, [quillRef]);

  return (
    <ReactQuill
      ref={quillRef}
      value={value}
      onChange={onChange}
      modules={modules}
      formats={formats}
      theme={theme}
    />
  );
});

export default CustomReactQuill;