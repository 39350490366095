// LanguagesList.js

const LanguagesList = {
    languages: {
        "Français": "fr",
        "Deutsch": "de",
        "Italiano": "it",
        "English": "en",
        "Español": "es",
        "Português": "pt",
        "Afrikaans": "af",
        "Arabic": "ar",
        "Armenian": "hy",
        "Azerbaijani": "az",
        "Belarusian": "be",
        "Bosnian": "bs",
        "Bulgarian": "bg",
        "Catalan": "ca",
        "Chinese": "zh",
        "Croatian": "hr",
        "Czech": "cs",
        "Danish": "da",
        "Dutch": "nl",
        "Estonian": "et",
        "Finnish": "fi",
        "Galician": "gl",
        "Greek": "el",
        "Hebrew": "he",
        "Hindi": "hi",
        "Hungarian": "hu",
        "Icelandic": "is",
        "Indonesian": "id",
        "Japanese": "ja",
        "Kannada": "kn",
        "Kazakh": "kk",
        "Korean": "ko",
        "Latvian": "lv",
        "Lithuanian": "lt",
        "Macedonian": "mk",
        "Malay": "ms",
        "Marathi": "mr",
        "Maori": "mi",
        "Nepali": "ne",
        "Norwegian": "no",
        "Persian": "fa",
        "Polish": "pl",
        "Romanian": "ro",
        "Russian": "ru",
        "Serbian": "sr",
        "Slovak": "sk",
        "Slovenian": "sl",
        "Swahili": "sw",
        "Swedish": "sv",
        "Tagalog": "tl",
        "Tamil": "ta",
        "Thai": "th",
        "Turkish": "tr",
        "Ukrainian": "uk",
        "Urdu": "ur",
        "Vietnamese": "vi",
        "Welsh": "cy"
    },

    // Function to find the language name by its code
    keyForCode: function (code) {
        return Object.keys(this.languages).find(key => this.languages[key] === code);
    }
};

export default LanguagesList;
